#migration-header {
    color: #282725;
    font-weight: bold;
    background-color: #fcd902;

    border: 0px !important;
    padding: 1.25rem 1rem 0.5rem 1rem !important;
}

#migration-body {
    color: #fafafa;
    background-color: #282725;
    padding: 1.5rem;
}

.keygen-area {
    cursor: pointer;

    padding: 1rem;

    text-align: center;
    font-size: 0.75rem;
    word-wrap: break-word;

    border: 1px solid #ededed;
    border-radius: 0.5rem;
}

.migration-key-input {
    color: #fcd902;
    text-align: center;

    width: 100%;
    padding: 1rem;

    border-radius: 0.5rem;
    border: 1px solid #fcd902;
}

.migration-key-input::placeholder {
    color: #fcd902;
}

.migration-password-input {
    color: #ededed;
    text-align: center;

    width: 100%;
    padding: 1rem;

    border-radius: 0.5rem;
    border: 1px solid #ededed;
}

.migration-password-input::placeholder {
    color: #ededed;
}

.migration-main-loading {
    display: table;
    width: 100%;
    height: 50px;
    text-align: center;
}

.migration-fof-loading {
    display: table-cell;
    vertical-align: middle;
}

.migration-fof-loading h1 {
    font-size: 50px;
    display: inline-block;
    padding-right: 12px;
}

.migration-platform-button {
    height: initial;
    border-radius: 15px;
    background-color: #fcd902;
}

.migration-wallet-button {
    height: initial;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    color: #fafafa !important;
    background-color: rgba(237, 237, 237, 0.2);

    border-radius: 15px;
    border: 1px solid #fcd902 !important;
}
