.custom-h1
{
   margin: 0;
   padding: 0;
   text-align: left;
   font-family: inherit;
   font-weight: inherit;
}

.game-icon
{
    height: 40px;
    width: 40px;
    display: inline-block;
    margin-right: 10px;
}