.custom-link
{
    text-decoration: none;
    color: #b2b2b2 !important;
    font-size: 18px;

    &.chosen
    {
        color: #656565 !important; 
    }
}

.responsive
{
    @media only screen and (max-width : 991px)
    {
        width: 100%;
        align-items: center;
        text-align: center; 
        margin: auto; 
        display: flex;
        justify-content: center;
    }
}
