.bugSpotting
{
    .bordered-custom
    {
        border-top: 1px solid !important;
        border-bottom: 1px solid !important;

        &.accepted
        {
            border-top-color: #00cc69 !important;
            border-bottom-color: #00cc69 !important;
        }

        &.neutral
        {
            border-top-color: #b2b2b2 !important;
            border-bottom-color: #b2b2b2 !important;
        }

        &.rejected
        {
            border-top-color: #f44336 !important;
            border-bottom-color: #f44336 !important;
        }
    }

    img
    {
        max-width: 90%
    }

    .bug-status
    {
        &.accepted
        {
            font-size: 14px !important;
            color: #00cc69 !important;
        }

        &.neutral
        {
            font-size: 14px !important;
            color: #b2b2b2 !important;
        }

        &.rejected
        {
            font-size: 14px !important;
            color: #f44336 !important;
        }
    }

    .reward-amount
    {
        color: #fcd902 !important;
        font-size: 24px !important;

        &.qudo
        {
            font-size: 14px !important;
        }
    }
}