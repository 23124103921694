.message {
    margin-top: 2px;
    padding: 0.75rem;
    border-radius: 1rem;
    text-align: left !important;
    white-space: pre-wrap;
}

.message-sent {
    @extend .message;

    color: #282725;
    background-color: #fcd902;
}

.message-received {
    @extend .message;

    color: #fcd902;
    background-color: #282725;
}

.message-empty {
    @extend .message;

    color: black;
    background-color: lightgray;
}
