.rewards
{
    &.numbers
    {
        font-size: 30px !important;
        color: #b2b2b2;
    }

    &.type
    {
        font-size: 17px !important;
    }
}

.report-data
{
    color: #b2b2b2;
}

.final-status
{
    font-size: 3rem;
    &.accepted
    {
        color: green !important;
    }

    &.rejected
    {
        color: red !important;
    }
}