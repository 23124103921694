.filters
{
    display: flex;
    justify-content: space-between;
}

.name
{
    color: #656565 !important;
}

.date
{
    color: #b2b2b2;
    font-size: 11px !important;
}

.status
{
    padding: 0.3rem 0;

    &.accept
    {
        border-top: 2px solid green;
        border-bottom: 2px solid green;
        color: green;
    }
    
    &.await
    {
        border-top: 2px solid grey;
        border-bottom: 2px solid grey;
        color: grey;
    }

    &.reject
    {
        border-top: 2px solid red;
        border-bottom: 2px solid red;
        color: red;
    }
}

.cursor-pointer
{
    cursor: pointer;
}

.selected
{
    background-color: #eeeeee;
}