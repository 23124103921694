#circle 
{
    display: block;
    border: 1px solid grey;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin: 0;
    
    &.active 
    {
        background: #fcd902;
    }
}

.indicator
{
    cursor: pointer !important;
}