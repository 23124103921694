.rating
{
    align-items: center;
    vertical-align: middle;
    width: 100% !important;

    .rating-number
    {
        font-size: 3rem !important;
        color: #fcd902 !important;
    }

    .people
    {
        font-size: 0.7rem !important;
        color: grey !important;
        white-space: nowrap;
        vertical-align: bottom !important;
        align-items: center;
        width: 100%;
    }
}

.margin-mobile
{
    @media only screen and (max-width: 575px) 
    {
        margin-bottom: 1rem !important;
    }
}
.custom-header
{
    margin: 0;
    padding: 0;
    font-size: 13px !important;
    color: #b2b2b2 !important;
    font-weight: 500;
}

.button-hire
{
    &.min-w-60
    {
        min-width: 60%;
        background-color: #282725;
        color: #fcd902;
        vertical-align: middle;
        align-items: center;
        padding: 0.3rem 1.75rem;
        border: 1px solid transparent;
        border-radius: 0.5rem 0 0 0.5rem !important;
        font-size: 1.5rem;
    }

    &.min-w-40
    {
        min-width: 40%;
        border-radius: 0 0.5rem 0.5rem 0 !important;
    }

    .price
    {
        font-size: 20px;
        color: #ededed;
    }

    .numbers
    {
        font-size: 2rem;
        font-weight: 500;
    }

    &.dark
    {
        border: 1px solid #656565;
        
    }
}

.hire-service-modal
{
    color: #ffffff;
    font-size: 13px;

    .title
    {
        font-size: 20px;
    }

    .subtitle
    {
        color: #b2b2b2;
    }
}

.pointer
{
    cursor: pointer;
}