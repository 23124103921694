.bug-title
{
    font-size: 14px !important;
    line-height: 2rem !important;
}

.bordered
{
    border-top: 1px solid !important;

    &.accepted
    {
        border-top-color: #00cc69 !important;
    }

    &.neutral
    {
        border-top-color: #b2b2b2 !important;
    }

    &.rejected
    {
        border-top-color: #f44336 !important;
    }
}

.reward-amount
{
    color: #fcd902 !important;
    font-size: 24px !important;

    &.qudo
    {
        font-size: 14px !important;
    }
}

.bug-status
{
    &.accepted
    {
        font-size: 14px !important;
        color: #00cc69 !important;
    }

    &.neutral
    {
        font-size: 14px !important;
        color: #b2b2b2 !important;
    }

    &.rejected
    {
        font-size: 14px !important;
        color: #f44336 !important;
    }
}

.title-description
{
    font-size: 12px !important;
    color: #656565 !important;
    font-weight: 500 !important;
}

.description
{
    font-size: 12px !important;
    color: #656565 !important;
    font-weight: 300 !important;
}