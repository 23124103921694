.button-text
{
    white-space: nowrap;
    width: 90% !important;
    text-align: center !important;
    vertical-align: middle;
}

.header
{
    font-size: 16px !important;
    padding-top: 1.5vh !important;
}
.updated
{
    color: #b2b2b2 !important;
    font-size: 12px !important;
}

.submenus
{
    font-size: 15px !important;
    font-weight: normal !important;
    white-space: nowrap;
}

.borders
{
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.deltas
{
    font-size: 30px !important;

    &.value
    {
        color: #fbd731;
    }
}

.green
{
    color: #00cc69 !important;
}
.red
{
    color: #f44336 !important;
}

.grey
{
    color: rgb(178, 178, 178) !important;
}

.button
{
    width:100% !important;
    border: 1px solid #ededed !important;

    &.disabled
    {
        cursor: not-allowed !important;
        color: #ededed !important;
    }
}

.white-space
{
    height: 1rem;
}

@media (max-width : 1200px) 
{
    .button-text
    {
        white-space: normal;
        vertical-align: middle;
    }

    .editgamebtn
    {
        padding: 0.5rem 0 !important;
    }
}

.cursor-pointer
{
    cursor: pointer;
}
